<template>
  <vx-card title="Pricing Management SRC">
    <div class="tab-text">
      <div class="vx-row p-3 flex">
        <vs-button
          class="mt-2"
          color="primary"
          type="border"
          icon-pack="feather"
          icon="icon-plus"
          @click="handleCreate()"
          >Create</vs-button
        >
      </div>
      <div class="vx-row mb-12">
        <div v-bind:class="[detail ? detailShow : '', detailHide]">
          <vs-table
            search
            stripe
            border
            description
            :sst="true"
            :data="table.data"
            :max-items="table.length"
            :total="table.total"
            @search="handleSearch"
            @change-page="handleChangePage"
            @sort="handleSort"
          >
            <template slot="header">
              <vs-dropdown vs-trigger-click class="cursor-pointer">
                <div
                  class="
                    p-3
                    border border-solid
                    d-theme-border-grey-light
                    rounded-full
                    d-theme-dark-bg
                    cursor-pointer
                    flex
                    items-center
                    justify-between
                    font-medium
                  "
                >
                  <span class="mr-2"
                    >{{ this.table.start }} - {{ this.table.end }} of
                    {{ this.table.total }}</span
                  >
                  <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                  <vs-dropdown-item
                    v-for="item in table.limits"
                    :key="item"
                    @click="handleChangelength(item)"
                  >
                    <span>{{ item }}</span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </template>
            <template slot="thead">
              <vs-th width="10%"></vs-th>
              <vs-th sort-key="code">Code</vs-th>
              <vs-th sort-key="name">Name</vs-th>
              <vs-th width="25%" sort-key="notes">Notes</vs-th>
              <vs-th sort-key="status">Status</vs-th>
              <vs-th sort-key="valid_from">Valid</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td class="whitespace-no-wrap">
                  <feather-icon
                    title="View Detail"
                    icon="ListIcon"
                    svgClasses="w-5 h-5 hover:text-success stroke-current"
                    @click.stop="handleViewDetail(data[indextr].id)"
                  />
                  <feather-icon
                    title="Edit"
                    icon="EditIcon"
                    svgClasses="w-5 h-5 hover:text-primary stroke-current"
                    class="ml-2"
                    @click.stop="handleEdit(data[indextr].id)"
                  />
                  <feather-icon
                    title="Delete"
                    icon="TrashIcon"
                    svgClasses="w-5 h-5 hover:text-danger stroke-current"
                    class="ml-2"
                    @click.stop="handleDelete(data[indextr].id)"
                  />
                </vs-td>
                <vs-td :data="data[indextr].code">{{
                  data[indextr].code
                }}</vs-td>
                <vs-td :data="data[indextr].name">{{
                  data[indextr].name
                }}</vs-td>
                <vs-td
                  v-if="data[indextr].notes.length < 141"
                  :data="data[indextr].notes"
                  >{{ data[indextr].notes }}</vs-td
                >
                <vs-td v-else :data="data[indextr].notes">{{
                  data[indextr].notes.substring(0, 141) + ".."
                }}</vs-td>
                <vs-td :data="data[indextr].status">{{
                  data[indextr].status
                }}</vs-td>
                <vs-td :data="data[indextr].valid_from">
                  <div class="vx-row ml-0">
                    <span class="w-1/5">From</span>
                    <span class="w-4/5">: {{ data[indextr].valid_from }}</span>
                  </div>
                  <div class="vx-row ml-0">
                    <span class="w-1/5">To</span>
                    <span class="w-4/5">: {{ data[indextr].valid_to }}</span>
                  </div>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <vs-pagination
            style="padding-top: 5px"
            :total="table.totalPage"
            v-model="setPage"
          />
        </div>
      </div>
      <vs-prompt
        title="Confirm"
        @cancel="val = null"
        @accept="acceptSecurityCode"
        :active.sync="promptInsertPass"
      >
        <div class="con-exemple-prompt">
          <span>Enter the password</span>
          <vs-input
            placeholder="Password"
            type="password"
            vs-placeholder="Password"
            v-model="val"
            class="mt-3 w-full"
          />
        </div>
      </vs-prompt>
    </div>
  </vx-card>
</template>
<script>
import ListPriceForm from "./ListPriceForm";
export default {
  components: {
    ListPriceForm,
  },
  data() {
    return {
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      is_partial: 0,
      deleteId: null,
      statusLock: 0,
      val: null,
      statusPrompt: "",
      promptInsertPass: false,
      idEdit: 0,
      table: {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "status",
        sort: "asc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      },
    };
  },
  methods: {
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      this.getData();
      this.detail = false;
    },
    handleCreate() {
      this.priceId = null;
      this.$router.push("pricing-management-src/list-price/create");
    },
    acceptSecurityCode() {
      if (this.val != null) {
        this.$http
          .post("/api/v1/master/price/validate", {
            password: this.val,
            user_agent: navigator.userAgent,
          })
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              if (this.statusPrompt == "edit") {
                // if (this.is_partial == 1) {
                //   this.$router.push(
                //     "pricing-management/list-price/edit-partial/" + this.idEdit
                //   );
                // } else {
                  this.$router.push(
                    "pricing-management-src/list-price/edit/" + this.idEdit
                  );
                // }
              } else if (this.statusPrompt == "delete") {
                this.$vs.dialog({
                  type: "confirm",
                  color: "danger",
                  title: `Confirm`,
                  text: "Please confirm to delete this list price data",
                  accept: this.acceptDelete,
                });
              } else if (this.statusPrompt == "create") {
                // if (this.is_partial == 1) {
                //   this.$router.push(
                //     "pricing-management/list-price/create-partial"
                //   );
                // } else {
                  this.$router.push("pricing-management-src/list-price/create");
            //     }
              }
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: "Security code does not match",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
            this.val = null;
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
      } else {
        this.$vs.notify({
          color: "danger",
          title: "Closed",
          text: "Please insert the security code!",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/prices-src", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
          },
        })
        .then((resp) => {
          var _this = this
          if (resp.status == "success") {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          }
        });
        
      this.$http.get("/api/v1/master/price/status-lock").then((resp) => {
        if (resp.status == "success") {
          this.statusLock = resp.data;
        }
      });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleDelete(id) {
      this.detail = false;
      this.deleteId = id;

      if (this.statusLock == 1) {
        this.promptInsertPass = true;
        this.statusPrompt = "delete";
      } else {
        this.$vs.dialog({
          type: "confirm",
          color: "danger",
          title: `Confirm`,
          text: "Please confirm to delete this list price data",
          accept: this.acceptDelete,
        });
      }
    },
    acceptDelete() {
      this.$vs.loading();
      this.$http
        .delete("/api/v1/master/price-src/" + this.deleteId)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "List price deleted",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.getData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleEdit(id) {
      if (this.statusLock == 1) {
        this.promptInsertPass = true;
        this.statusPrompt = "edit";
        this.idEdit = id;
        // this.is_partial = is_partial;
      } else {
        // if (is_partial == 1) {
        //   this.$router.push("pricing-management/list-price/edit-partial/" + id);
        // } else {
          this.$router.push("pricing-management-src/list-price/edit/" + id);
        // }
      }
    },
    handleViewDetail(id) {
      this.$router.push("pricing-management-src/list-price/detail/" + id);
    },
  },
  mounted() {
    // this.getData();
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>