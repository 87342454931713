<template>
    <div class="vx-row">
        <div class="vx-col md:w-1/1 w-full">
            <vx-card>
                <span>
                    <h4 class="mb-5" v-if="this.$route.params.id">Edit List Price</h4>
                    <h4 class="mb-5" v-else>Create List Price</h4>
                </span>
                <div class="vx-row mb-6" style="width:100%;">
                    <vs-button
                        class="ml-4 mt-2"
                        color="danger"
                        icon-pack="feather"
                        icon="icon-arrow-left"
                        @click="handleBack()"
                    >Back</vs-button>
                </div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Code</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <vs-input class="w-full" name="Code" v-model.lazy="data.code" readonly/>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Name</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <vs-input class="w-full" name="name" :value="data.name" @change="data.name = $event.target.value"/>
                        <span
                            class="text-danger text-sm"
                            v-show="errors.has('name')"
                            >{{ errors.first('name') }}
                        </span>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Notes</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <!-- <vs-input class="w-full" name="Notes" :value="data.notes" @change="data.notes = $event.target.value"/> -->
                        <vs-textarea class="w-full" maxlength ="255" name="Notes" :value="data.notes" @change="data.notes = $event.target.value" />
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Valid From</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <datepicker v-validate="'required'" name="valid from" :inline="false" v-model.lazy="data.valid_from" placeholder="Select Date"></datepicker>
                        <span
                            class="text-danger text-sm"
                            v-show="errors.has('valid from')"
                            >{{ errors.first('valid from') }}
                        </span>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Valid To</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <datepicker v-validate="'required'" name="valid to" :inline="false" v-model.lazy="data.valid_to" placeholder="Select Date"></datepicker>
                        <span
                            class="text-danger text-sm"
                            v-show="errors.has('valid to')"
                            >{{ errors.first('valid to') }}
                        </span>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Status Active</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
                        <div class="vx-col w-full">
                            <vs-switch v-model="data.is_active"/>
                        </div>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Items</span>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4 mt-2">
					<div class="vx-col sm:w-5/5 w-full mb-2" v-for="(tr, indextr) in items" :key="indextr">
                        <div class="vx-row mb-2">
                            <div class="vx-col w-3/5" style="display: flex">
                                <span style="margin: auto; width: 5%" class="mr-3" v-if="items[indextr].num != 0" >{{items[indextr].num}}.</span>
                                <span style="margin: auto; width: 5%" class="mr-3" v-else></span>
                                <vs-input label="Item" class="w-full" :value="items[indextr].label" readonly/>
                            </div>
                            <div class="vx-col w-2/5" style="display: flex">
                                <div class="vx-col w-1/2 mr-1">
                                    <vs-input @keypress="isNumber($event)" label="Price" :value="items[indextr].price" @change="items[indextr].price = $event.target.value" class="w-full" name="Price"/>
                                </div>
                                <div class="vx-col w-1/2 mr-1">
                                    <vs-input @keypress="isNumber($event)" label="Discount Price" :value="items[indextr].discount_price" @change="items[indextr].discount_price = $event.target.value" class="w-full" name="Discount Price"/>
                                </div>
                            </div>
                        </div>
                    </div>
				</div>
                <content-placeholders v-if="statusPlaceholder == true" class="mb-6 ml-4 mr-4">
                    <div class="vx-col sm:w-5/5 w-full mb-2">
                        <div class="vx-row mb-2">
                            <div class="vx-col w-2/5" style="display: flex">
                                <content-placeholders-text style="margin: auto; width: 5%" class="ml-4 mr-3" :lines="1" />
                                <content-placeholders-text class="w-full" :lines="2" />
                            </div>
                            <div class="vx-col w-2/5" style="display: flex">
                                <div class="vx-col w-1/3 mr-1">
                                    <content-placeholders-text class="w-full" :lines="2" />
                                </div>
                                <div class="vx-col w-1/3 mr-1">
                                    <content-placeholders-text class="w-full" :lines="2" />
                                </div>
                                <div class="vx-col w-1/3">
                                    <content-placeholders-text class="w-full" :lines="2" />
                                </div>
                            </div>
                            <div class="vx-col w-1/5" style="display: flex">
                                <div class="vx-col w-1/2">
                                    <content-placeholders-text class="w-full" :lines="2" />
                                </div>
                            </div>
                        </div>
                    </div>
                </content-placeholders>

                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col">
                        <vs-button class="mr-3 mb-2" @click="handleSubmit" v-if="this.$route.params.id">Update</vs-button>
						<vs-button class="mr-3 mb-2" @click="handleSubmit" v-else>Submit</vs-button>
					</div>
				</div>
                
            </vx-card>
        </div>
    </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";

export default {
    components: {
        Datepicker,
        vSelect
    },
    created() {
        // this.getDataListPrice()
    },
    data() {
        return {
            // statusUpdate: false,
            button: true,
            items: [],
            notes: "",
            statusPlaceholder: true,
            data: {
                code: "",
                name: "",
                notes: "",
                valid_from: "",
                valid_to: "",
                is_active: true
            },
        };
    },
    methods: {
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        handleSubmit() {
            this.$validator.validateAll().then(result => {
                if (result) {
                    this.$vs.loading();
                    var _this = this
                    this.items.forEach(function(element, index){
                        _this.items[index].price = element.price.toString()
                        _this.items[index].discount_price = element.discount_price.toString()
                    })

                    // if(this.statusUpdate){
                    if(this.$route.params.id){
                        this.putData();
                    } else {
                        this.postData();
                    }
                }
            });
        },
        handleBack() {
            this.$router.push("/master/pricing-management-src");
        },
        paramData() {
            return {
                code: this.data.code,
                name: this.data.name,
                notes: this.data.notes,
                valid_from: this.data.valid_from,
                valid_to: this.data.valid_to,
                status_active: this.data.is_active,
                item: this.items
            };
        },
        getCode() {
        this.$vs.loading();
            this.$http
            .get("/api/v1/master/price-src/code")
            .then(resp => {
                this.$vs.loading.close();
                if (resp.code == 200) {
                    this.data.code = resp.data
                    // this.getItemUnit();
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
                
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        postData() {
            // console.log(this.paramData());
            this.$http
                .post("/api/v1/master/price-src", this.paramData())
                .then(resp => {
                this.$vs.loading.close();
                if (resp.code == 200) {
                    this.button = false
                    // this.statusUpdate = true
                    this.handleClose();
                    this.$vs.notify({
                        color: "success",
                        title: "Success",
                        text: "New List Price Created",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        putData() {
            this.$http
            .put("/api/v1/master/price-src/"+this.$route.params.id, this.paramData())
            .then(resp => {
            this.$vs.loading.close();
            if (resp.code == 200) {
                this.handleClose();
                this.$vs.notify({
                    color: "success",
                    title: "Success",
                    text: "Price List Updated",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
            } else {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: resp.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
            }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        handleClose() {
            window.scrollTo(0, 0);
            this.$emit("close");
        },
        getData() {
            console.log("getData")
            this.$vs.loading();
            this.$http
            .get("/api/v1/master/price-src/"+this.$route.params.id)
            .then(resp => {
                var _this = this
                this.$vs.loading.close();
                if (resp.code == 200) {                    
                    this.statusPlaceholder = true
                    this.data.code = resp.data.code;
                    this.data.name = resp.data.name;
                    this.data.notes = resp.data.notes;
                    this.data.valid_from = resp.data.valid_from;
                    this.data.valid_to = resp.data.valid_to;
                    this.data.is_active = resp.data.status_active;
                    setTimeout(function(){
                        _this.items = []
                        for(var k = 0; k < resp.data.list.length; k++){
                            _this.items.push({
                                num: resp.data.list[k].num,
                                label: resp.data.list[k].label,
                                sku_code: resp.data.list[k].sku_code,
                                item_unit: resp.data.list[k].item_unit,
                                item_unit_id: resp.data.list[k].item_unit_id,
                                item_name: resp.data.list[k].item_name,
                                price: resp.data.list[k].price.toString(),
                                discount_price: resp.data.list[k].discount_price.toString(),
                                valid_from: resp.data.list[k].valid_from,
                                valid_to: resp.data.list[k].valid_to,
                                status_item: "old",
                            })                            
                        }
                        if(_this.items.length == resp.data.list.length){
                            _this.statusPlaceholder = false
                        }
                        if(resp.data.list.length == 0){
                            _this.getItemUnit()
                        } else {
                            _this.button = false
                            // _this.statusUpdate = true
                            _this.getNewItem()
                        }
                    }, 0);
                }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        getItemUnit() {
            console.log("getItemUnit")
            this.$vs.loading();
            this.$http
            .get("/api/v1/master/item-unit")
            .then(resp => {
                this.$vs.loading.close();
                var _this = this
                if (resp.code == 200) {
                    for(var k = 0; k < resp.data.length; k++){
                        _this.items.push({
                            num: k+1,
                            item_unit_id: resp.data[k].item_unit_id,
                            label: resp.data[k].label,
                            item_name: resp.data[k].item_name,
                            sku_code: resp.data[k].sku_code,
                            item_unit: resp.data[k].item_unit,
                            price: "0",
                            discount_price: "0",
                            valid_from: "",
                            valid_to: "",
                            status_item: "old",
                        })
                    }
                    if(_this.items.length == resp.data.length){
                        _this.statusPlaceholder = false
                    }
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        getNewItem() {
            console.log("getNewItem")
            this.$http
            .get("/api/v1/master/new-item-unit-src")
            .then(resp => {
                var _this = this
                this.$vs.loading.close();
                if (resp.code == 200) {
                    
                    setTimeout(function(){
                        var x = _this.items.length
                        for(var k = 0; k < resp.data.length; k++){
                            _this.items.push({
                                num: x+k+1,
                                item_unit_id: resp.data[k].item_unit_id,
                                label: resp.data[k].label,
                                item_name: resp.data[k].item_name,
                                sku_code: resp.data[k].sku_code,
                                item_unit: resp.data[k].item_unit,
                                price: "0",
                                discount_price: "0",
                                valid_from: "",
                                valid_to: "",
                                status_item: "new",
                            })
                            
                        }
                        if(_this.items.length == resp.data.length){
                            _this.statusPlaceholder = false
                        }
                    }, 0); 

                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        cit() {
        this.$vs.loading();
            this.$http
            .get("/api/v1/master/item-unit")
            .then(resp => {
                this.$vs.loading.close();
                var _this = this
                if (resp.code == 200) {
                    for(var k = 0; k < resp.data.length; k++){
                        var unit = resp.data[k].item_unit;
                        var p = []
                        if(unit == 'Pcs'){
                            p = [8000, 7000]
                        } else if(unit == 'Bal') {
                            p = [40000, 39000]
                        } else if(unit == 'Carton') {
                            p = [250000, 240000]
                        } else if(unit == 'Dus 1000') {
                            p = [350000, 340000]
                        } else if(unit == 'Pack 50') {
                            p = [50000, 49000]
                        } else if(unit == 'Bag 120') {
                            p = [150000, 140000]
                        } else if(unit == 'Bag 20') {
                            p = [70000, 69000]
                        } else if(unit == 'Bal 50') {
                            p = [90000, 89000]
                        } else if(unit == 'Pack 10') {
                            p = [20000, 19000]
                        } else if(unit == 'Dus 40') {
                            p = [50000, 49000]
                        } else if(unit == 'Pack 5') {
                            p = [10000, 9000]
                        } else if(unit == 'Dus 72') {
                            p = [120000, 119000]
                        } else if(unit == 'Dus 12') {
                            p = [34000, 33000]
                        } else if(unit == 'Bag') {
                            p = [9000, 8000]
                        } else if(unit == 'Dus 20') {
                            p = [54000, 53000]
                        } else {
                            p = [0, 0, 0]
                        }
                        
                        var i = 0
                        _this.items.push({
                            num: k+1,
                            label: resp.data[k].label,
                            item_name: resp.data[k].item_name,
                            sku_code: resp.data[k].sku_code,
                            item_unit: resp.data[k].item_unit,
                            item_unit_id: resp.data[k].item_unit_id,
                            price: p[i],
                            discount_price: p[i+1],
                            valid_from: "",
                            valid_to: "",
                        })
                    }
                    _this.statusPlaceholder = false
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
    },
    mounted() {
        if(!(this.$route.params.id)){
            if (this.$route.query.cit){
                this.cit();
                this.getCode();
            } else {
                this.getItemUnit();
                this.getCode();
            }
        } else {
            this.getData();
        }
    },
    computed: {},
    watch: {}
};
</script>